// If you want to override variables do it here
@import "variables";

@import "~@coreui/coreui/dist/css/coreui.min.css";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";
