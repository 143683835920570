// Here you can add other styles

.text-danger {
  color: red;
}
.ng-valid[required], .ng-valid.required  {
  border-left: 5px solid #42A948; /* green */
}

.ng-invalid:not(form)  {
  border-left: 5px solid #FF0000FF; /* red */
}

.alert-danger {
 padding: 0;
}


.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  text-align: center;
  /* Black background with opacity */
  background: rgba(0, 0, 0, 0.5) url('/assets/images/loader.png') no-repeat center;
  z-index: 2000; /* Specify a stack order in case you're using a different order for other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay > span {
  margin-top: -50px;
}

agm-map{
  height: 300px
}
